/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

/*Sign In Form*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
/*Website*/
@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sawarabi+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap');

@font-face {
    font-family: sunbird;
    src: url(assets/font-family/Sunbird.otf) format("opentype");
}
@font-face {
    font-family: trajanpro;
    src: url(assets/font-family/trajanpro.otf) format("opentype")
}

html, body { 
    height: 100%;
    font-family: 'Crete Round';
}
.your-class {
    background-color: yellow;
}
div.JALang {
    font-family: 'Sawarabi Mincho', sans-serif;
    font-style: 'normal' !important;
}

blockquote {
    font-size: 3 rem;
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee
}

.content {
    font-family: 'Crete Round', serif;
    font-style: italic;
    font-size: large;
}
.JALang .content {
    font-family: 'Sawarabi Mincho', sans-serif;
    font-style: normal;
}


.JALang .content {
    font-style: normal;
}

.side-image {
    border: 1px solid black;
    max-width: 100%;
}

.side-image.selects {
    margin-top: 1rem;
    border: 1px solid black;
}

.sub-head {
    font-weight: bold;
    font-size: larger;
    font-style: normal;
    text-align: center;
}
.center-div {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.form-image {
    max-width: 50%;
}
.center-image{  
    max-width: 700px;
}

.page-heading {
    text-align: center;
    padding-top: 2rem;
}

.ContactUsBox img {
    display: block;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.form-control.ng-touched.ng-invalid {
    border: 2px solid red;
}

.form-control-label-inline {
    font: bold;
}

.form-check-input {
    cursor: pointer !important;
}


/*For Carousel Animation */

.animated {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }
 @-webkit-keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
 }
 @keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
 }
 .fadeIn {
   -webkit-animation-name: fadeIn;
   animation-name: fadeIn;
 }

.form-border {
    border:1px solid #000;
    border-radius: 10px 10px 10px 10px;
    padding: 1rem 0 1rem 0
}


.pointer {
    cursor: pointer;
}

.tab-content {
    width: 100%;
    margin-top: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.form-control-label {
    font-weight: bold;
}
mat-label.required::after {
	content: '* ';
	color: red;
}
.form-control-label.required::after {
    content: "*";
    color: red;
    margin-left: 1px
}

.marginb {
    margin-bottom: 1em;
}

.button-center {
    margin-left: 40%;
}

.form-button {
    margin-top: 1em;
}

.button-right {
    float: right;
}

.image-preview {
    max-height: 100%;
    height: 90%;
    width: 90%;
    object-fit: contain;
}

.image-preview1 {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.fa.click {
    cursor: pointer
}



.refresh-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


/* Table */

table {
    width: 100%;
}

.load-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    flex-grow: 1;
}

fa-icon.view :hover {
    color: lightseagreen;
    cursor: pointer !important;
}

fa-icon.info :hover {
    color: #1ABC9C;
    cursor: pointer !important;
}

fa-icon.edit :hover {
    color: limegreen;
    cursor: pointer !important;
}

fa-icon.delete :hover {
    color: red;
    cursor: pointer !important;
}


/* Form Pages */

.form-page {
    margin: 2em 2em 0 2em;
}

mat-form-field {
    width: 100%;
    appearance: standard;
    margin-top: 1em;
}

input.number {
    text-align: right;
}

input.number::-webkit-outer-spin-button,
input.number::-webkit-inner-spin-button {
    display: none;
}

input.number {
    -moz-appearance: textfield;
}

.review-form {
    display: flex;
    justify-content: space-between;
}

.review-form > .title {
    font-weight: bold;
    width: 40%;
}

.review-form > .value-input {
    width: 60%;
}

.review-form > .value-text-area {
    overflow-wrap: break-word;
    width: 60%;
}

.review-form > .value-keyword {
    color: green;
}

/* Data-list-Page */

.data-list-page {
    margin: 2em 2em 0 2em;
}

.heading>h2 {
    font-weight: bolder;
}

.content-page-background {
    background-color: #fafafa;
}

/* Auth Page */
.auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh
};


/******** Dashboard Styles *******/
.grid-container {
    margin: 20px;
}
  
  .dashboard-card {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }
  
  .more-button {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  
  .dashboard-card-content {
    text-align: center;
  }
  
  .count {
    color: #1ABC9C;
    font-size: 1.5em;
  }
  
.quick-add-card {
    width: 24%;
    margin: 0 auto;
}

/* Form */
.form-reset-password {
  margin-left: auto;
  margin-right: auto;
}

.psy-manage-layout {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .psy-manage-layout {
        flex-direction: column;
    }
}